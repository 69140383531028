import { createStore } from 'vuex';

import bingApi from '@/bingMaps';
import customApi from '@/api';

import config from '@/config';
import {
  round, encodeUrlTitle, headingFormatted, isHeadless,
} from '@/helpers';

const api = config.ENABLE_CUSTOM_API ? customApi : bingApi;

const store = createStore({
  state: {
    loading: true,
    setLocationOpen: false,
    location: {},
    elevation: {},
    useFeet: true,
    supportsElevation: false,
    watchId: null,
    colorScheme: isHeadless() ? "auto" : localStorage.getItem("colorScheme") || "auto",
    ezoicAds: {},
  },
  getters: {
    hasElevationValue(state) {
      return Number.isFinite(state.elevation.value);
    },
    hasLocation(state) {
      return typeof state.location.longitude !== 'undefined'
        && typeof state.location.latitude !== 'undefined';
    },
    displayCoordinates(state) {
      if (!state.location) {
        return null;
      }
      return `${round(state.location.latitude, 5)}, ${round(state.location.longitude, 5)}`;
    },
  },
  actions: {
    getUserLocation({ dispatch }) {
      if (isHeadless() || typeof navigator.geolocation === 'undefined') {
        dispatch('locationError');
        return;
      }
      navigator.geolocation.getCurrentPosition(
        (location) => {
          dispatch('setUserLocation', location);
        },
        (error) => dispatch('locationError', error),
        {
          enableHighAccuracy: true,
          timeout: 30 * 1000,
        },
      );
    },
    watchUserLocation({ state, commit, dispatch }) {
      if (state.watchId !== null) {
        dispatch('stopWatchingUserLocation');
      }
      if (isHeadless() || typeof navigator.geolocation === 'undefined') {
        dispatch('locationError');
        return;
      }
      const watchId = navigator.geolocation.watchPosition(
        (location) => {
          // only update location if the watch is still active
          if (state.watchId) {
            dispatch('setUserLocation', location);
          }
        },
        (error) => dispatch('locationError', error),
        {
          enableHighAccuracy: true,
          timeout: 30 * 1000,
        },
      );
      commit('setItem', { item: 'watchId', value: watchId });
    },
    setUserLocation({ state, dispatch, commit }, location) {
      const previousLocation = state.location;
      dispatch('updateLocation', {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        accuracy: location.coords.accuracy,
      });
      if (location.coords.altitude) {
        dispatch('setSupportsElevation', true);
        commit('setElevation', {
          elevation: location.coords.altitude,
          source: 'phone',
          accuracy: location.coords.altitudeAccuracy,
        });
        dispatch('setLoading', false);
      } else if (state.location.latitude !== previousLocation.latitude
        || state.location.longitude !== previousLocation.longitude) {
        dispatch('fetchElevation');
      }
    },
    locationError({ commit, dispatch }) {
      // disable settings a location error when prerendering
      if (isHeadless()) {
        return;
      }
      commit('setItem', { item: 'loading', value: false });
      dispatch('setLocationOpen', true);
    },
    stopWatchingUserLocation({ state, commit }) {
      if (isHeadless() || typeof navigator.geolocation === 'undefined') {
        return;
      }
      navigator.geolocation.clearWatch(state.watchId);
      commit('setItem', { item: 'watchId', value: null });
    },
    async fetchElevation({ state, dispatch, commit }) {
      if (typeof navigator === 'undefined' || !navigator.onLine) {
        // console.info('offline, not fetching elevation');
        return false;
      }
      dispatch('stopWatchingUserLocation');
      let loaded = false;
      // delay showing the loading animation in case the response is super fast
      setTimeout(() => {
        if (!loaded) {
          dispatch('setLoading', true);
        }
      }, 200);
      try {
        const elevation = await api.getElevation({
          latitude: round(state.location.latitude, 5),
          longitude: round(state.location.longitude, 5),
        });
        loaded = true;
        commit('setElevation', { elevation, source: 'web' });
        dispatch('setLoading', false);
      } catch (error) {
        // console.warn(error);
        dispatch('setLocationOpen', true);
        dispatch('setLoading', false);
      }
    },
    updateLocation({ commit }, {
      latitude, longitude, accuracy = 100, title = null,
    }) {
      commit('setItem', {
        item: 'location',
        value: {
          latitude, longitude, accuracy, title,
        },
      });
    },
    clearLocations({ commit }) {
      commit('setItem', { item: 'locations', value: [] });
    },
    setLocationOpen({ commit }, isOpen) {
      commit('setItem', { item: 'setLocationOpen', value: isOpen });
    },
    setLoading({ commit }, isLoading) {
      commit('setItem', { item: 'loading', value: isLoading });
    },
    setUseFeet({ commit }, isFeet) {
      commit('setItem', { item: 'useFeet', value: isFeet });
    },
    setSupportsElevation({ commit }, supportsElevation) {
      commit('setItem', { item: 'supportsElevation', value: supportsElevation });
    },
    setColorScheme({ commit }, colorScheme) {
      localStorage.setItem('colorScheme', colorScheme);
      commit('setItem', { item: 'colorScheme', value: colorScheme });
    },
    setAd({ commit }, { id, html }) {
      commit('setAd', { id, html });
    },
    setSpeedUnits({ commit }, units) {
      localStorage.setItem('speedUnits', units);
      commit('setItem', { item: 'speedUnits', value: units });
    },
  },
  mutations: {
    setItem(state, { item, value }) {
      state[item] = value;
    },
    setAd(state, { id, html }) {
      state.ezoicAds[id] = html;
    },
    setElevation(state, { elevation, source, accuracy = null }) {
      state.elevation.value = elevation;
      state.elevation.source = source;
      state.elevation.accuracy = accuracy;
    },
  },
});

export default store;