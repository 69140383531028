import HoneybadgerVue from "@honeybadger-io/vue";
import { ViteSSG } from "vite-ssg";
import App from "./App.vue";
import routerOptions from "./router";
import store from "./store";
import i18n from "./i18n";
import { useRegisterSW } from "virtual:pwa-register/vue";

export const createApp = ViteSSG(
  // the root component
  App,
  // vue-router options
  routerOptions,
  // function to have custom setups
  ({ app, router, routes, isClient, initialState }) => {
    // install plugins etc.
    if (isClient) {
      router.beforeResolve((to, from) => {
        const lang = to.params.lang || import.meta.env.VITE_APP_I18N_LOCALE;
        i18n.global.locale = lang;
      });
      router.afterEach(to => {
        /*eslint-disable */
        if (typeof ga !== "undefined") {
          ga("set", "page", to.path);
          ga("send", "pageview");
        }
        /* eslint-enable */
      });

      if (!!import.meta.env.VITE_APP_HONEYBADGER_API_KEY) {
        app.use(HoneybadgerVue, {
          apiKey: import.meta.env.VITE_APP_HONEYBADGER_API_KEY,
          environment: import.meta.env.NODE_ENV
        });
      }

      useRegisterSW({
        onRegistered(r) {
          r &&
            setInterval(() => {
              r.update();
            }, 60 * 60 * 1000);
        }
      });
    }
    app.use(i18n);
    app.use(store);
  }
);
const app = createApp();

if (import.meta.env.DEV && !import.meta.env.SSR) {
  window.$vm = app;
}
