const ElevationPage = () => import("./views/ElevationPage.vue");
import { RouterView } from "vue-router";
const AtlasPage = () => import("./views/AtlasPage.vue");
const WikiAtlasPage = () => import("./views/WikiAtlasPage.vue");
const MapPage = () => import("./views/MapPage.vue");
const ShareCustomizerPage = () => import("./views/ShareCustomizerPage.vue");
const AddressPage = () => import("./views/AddressPage.vue");
const SpeedPage = () => import("./views/SpeedPage.vue");
const SpeedConverterPage = () => import("./views/SpeedConverterPage.vue");
const SpeedCalculatorPage = () => import("./views/SpeedCalculatorPage.vue");
const HowFarPage = () => import("./views/HowFarPage.vue");
const CountyAtlasPage = () => import("./views/CountyAtlasPage.vue");
const SnowTotalsPage = () => import("./views/SnowTotalsPage.vue");
const SnowAtlasPage = () => import("./views/SnowAtlasPage.vue");

const router = {
  routes: [
    {
      path: "/convert-elevation",
      redirect: { name: "home" }
    },
    {
      path: "/:lang(\\w{2})?",
      component: RouterView,
      children: [
        {
          path: "",
          name: "home",
          component: ElevationPage
        },
        {
          path: "county-map/:state?/:county?",
          name: "countyAtlas",
          component: CountyAtlasPage,
          props: true
        },
        {
          path: "",
          component: AddressPage,
          children: [
            {
              path: "what-is-my-address",
              name: "address",
              component: () => import("./pages/address/index.md")
            },
            {
              path: "what-is-my-city",
              name: "city",
              component: () => import("./pages/address/city.md")
            },
            {
              path: "what-is-my-zipcode",
              name: "zipcode",
              component: () => import("./pages/address/zipcode.md")
            },
            {
              path: "what-is-my-county",
              name: "county",
              component: () => import("./pages/address/county.md")
            },
            {
              path: "what-township-am-i-in",
              name: "township",
              component: () => import("./pages/address/township.md")
            },
            {
              path: "what-town-am-i-in",
              name: "town",
              component: () => import("./pages/address/town.md")
            },
            {
              path: "what-municipality-am-i-in",
              name: "municipality",
              component: () => import("./pages/address/municipality.md")
            },
            {
              path: "what-is-my-state",
              name: "state",
              component: () => import("./pages/address/state.md")
            },
            {
              path: "what-is-my-country",
              name: "country",
              component: () => import("./pages/address/country.md")
            },
            {
              path: "what-is-my-neighborhood",
              name: "neighborhood",
              component: () => import("./pages/address/neighborhood.md")
            },
            {
              path: "what-is-my-intersection",
              name: "intersection",
              component: () => import("./pages/address/intersection.md")
            },
            {
              path: "what-are-my-gps-coordinates",
              name: "coordinates",
              component: () => import("./pages/address/gps-coordinates.md")
            },
            {
              path: "what-school-district-am-i-in",
              name: "schoolDistrict",
              component: () => import("./pages/address/school-district.md")
            },
            {
              path: "what-zone-am-i-in",
              name: "zone",
              component: () => import("./pages/address/zone.md")
            },
            {
              path: "what-indigenous-territories-am-i-in",
              name: "nativeLands",
              component: () => import("./pages/address/native-lands.md")
            }
          ]
        },
        {
          path: "location/:latitude,:longitude/:title?/:elevation/share",
          name: "ShareCustomizer",
          component: ShareCustomizerPage,
          props: true
        },
        {
          path: "location/:latitude,:longitude/:title?/:wikipedia?",
          name: "location",
          component: ElevationPage,
          props: true
        },
        {
          path: "map/:latitude,:longitude/:title?",
          name: "map",
          component: MapPage,
          props: true
        },
        {
          path: "map",
          name: "mapHome",
          component: MapPage
        },
        {
          path: "atlas/:category/:country(Q\\w+)?/:title?/:page(\\d+)?",
          name: "wikiAtlas",
          component: WikiAtlasPage,
          props: true
        },
        {
          path: "atlas",
          name: "atlas",
          component: AtlasPage
        },
        {
          path: "how-fast-am-i-going",
          name: "speed",
          component: SpeedPage
        },
        {
          path: "speed-converter",
          name: "speedConverter",
          component: SpeedConverterPage
        },
        {
          path: "speed-calculator",
          name: "speedCalculator",
          component: SpeedCalculatorPage
        },
        {
          path: "how-far-is-it",
          name: "howFar",
          component: HowFarPage
        },
        {
          path: "snow-totals/atlas",
          name: "snowAtlas",
          component: SnowAtlasPage
        },
        {
          path: "snow-totals",
          name: "snowTotals",
          component: SnowTotalsPage
        },
        {
          path: "privacy",
          name: "privacy",
          component: () => import("./views/PrivacyPage.vue")
        }
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  }
};

export default router;
